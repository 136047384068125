@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.shiny-text {
  position: relative;
  display: inline-block;
  color: rgba(30, 30, 30, 1); /* 🔥 Dark Gray Base */
  background: linear-gradient(
    120deg,
    rgba(100, 100, 100, 1) 40%,  /* Slightly Lighter Gray */
    rgba(255, 255, 255, 0.6) 50%,  /* White Highlight */
    rgba(100, 100, 100, 1) 60%   /* Slightly Lighter Gray */
  );
  background-size: 200% 100%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  animation: shine 2s linear infinite;
}

@keyframes shine {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: -100%;
  }
}


.diff-add {
  background-color: #d1fae5;
  color: #065f46;
  padding: 0 2px;
  border-radius: 2px;
}

.diff-delete {
  background-color: #fee2e2;
  color: #b91c1c;
  padding: 0 2px;
  border-radius: 2px;
  text-decoration: line-through;
}